<template>
  <div class="photo-guide-content">
    <div class="top">
      <h4>Photo guide</h4>
      <p>
        Sharing quality photos that showcases your car is important for
        attracting potential buyers and increasing your chances of selling your
        car. Here are a few helpful tips to help you take the best photos of
        your car.
      </p>
    </div>
    <!-- Clean Up -->
    <div class="sections mx-auto text-left">
      <div class="section-1">
        <div class="d-flex justify-content-between align-items-center item">
          <h4 class="mb-0">{{ sections[0].title }}</h4>
          <img
            :aria-controls="sections[0].name"
            :aria-expanded="sections[0].isActive"
            @click="active(0)"
            :src="
              sections[0].isActive
                ? require('@/assets/images/icons/ic_minus.svg')
                : require('@/assets/images/icons/ic_plus.svg')
            "
          />
        </div>
        <b-collapse :id="sections[0].name" v-model="sections[0].isActive">
          <ul>
            <li>
              Remember to give your car a good clean - both the interior as
              well as the exterior of your car.
            </li>
            <li>
              Get rid of any unnecessary items and rubbish that may be inside
              or surrounding the car.
            </li>
            <li>Don't forget to clean out the trunk as well.</li>
          </ul>
        </b-collapse>
        <hr />
      </div>
      <!-- Location Matters -->
      <div class="section-2">
        <div class="d-flex justify-content-between align-items-center item">
          <h4 class="mb-0">{{ sections[1].title }}</h4>
          <img
            :aria-controls="sections[1].name"
            :aria-expanded="sections[1].isActive"
            @click="active(1)"
            :src="
              sections[1].isActive
                ? require('@/assets/images/icons/ic_minus.svg')
                : require('@/assets/images/icons/ic_plus.svg')
            "
          />
        </div>
        <b-collapse :id="sections[1].name" v-model="sections[1].isActive">
          <ul>
            <li>
              Start by choosing a nice, clean background - preferably
              outdoors.
            </li>
            <li>
              Avoid busy places where other vehicles, light poles, bins,
              graffiti, and other objects are part of the background.
            </li>
            <li>
              Objects in the background can distract the buyer and shift the
              focus away from your car.
            </li>
            <li>
              Be aware of objects in the background that may reflect in your
              car's paintwork.
            </li>
          </ul>
        </b-collapse>
        <hr />
      </div>
      <!-- Lighting -->
      <div class="section-3">
        <div class="d-flex justify-content-between align-items-center item">
          <h4 class="mb-0">{{ sections[2].title }}</h4>
          <img
            :aria-controls="sections[2].name"
            :aria-expanded="sections[2].isActive"
            @click="active(2)"
            :src="
              sections[2].isActive
                ? require('@/assets/images/icons/ic_minus.svg')
                : require('@/assets/images/icons/ic_plus.svg')
            "
          />
        </div>
        <b-collapse :id="sections[2].name" v-model="sections[2].isActive">
          <ul>
            <li>
              To get the best lighting for your car photos, take them on an
              overcast day where the entire car has consistent lighting, or in
              a shaded area.
            </li>
            <li>
              Photos taken in direct sunlight can create too much contrast for
              the camera and your photos will either be too dark with bright
              highlights, or too bright with no detail in the shadows.
            </li>
            <li>
              Avoid taking photos at night or undercover that can make your
              car hard to see clearly.
            </li>
          </ul>
        </b-collapse>
        <hr />
      </div>
      <!-- Choosing the Right Angles -->
      <div class="section-4">
        <div class="d-flex justify-content-between align-items-center item">
          <h4 class="mb-0">{{ sections[3].title }}</h4>
          <img
            :aria-controls="sections[3].name"
            :aria-expanded="sections[3].isActive"
            @click="active(3)"
            :src="
              sections[3].isActive
                ? require('@/assets/images/icons/ic_minus.svg')
                : require('@/assets/images/icons/ic_plus.svg')
            "
          />
        </div>
        <b-collapse :id="sections[3].name" v-model="sections[3].isActive">
          <p class="guided">Try our guided camera mode on mobile to take great photos.</p>
          <p>We recommend that you add following photos of your car:</p>
          <Guideline />
          <p class="mt-30">
            We also suggest taking any photos of any additional features or
            accessories your car may have, including sunroof, infotainment
            system/stereo, wireless charger, etc.
          </p>
        </b-collapse>
      </div>
      <!-- Photo Examples -->
    </div>
  </div>
</template>

<script>
export default {
  name: "Index",
  data() {
    return {
      sections: [
        {
          title: "Clean up",
          name: "clean-up",
          isActive: false,
        },
        {
          title: "Location matters",
          name: "location-matters",
          isActive: false,
        },
        {
          title: "Lightning",
          name: "lightning",
          isActive: false,
        },
        {
          title: "Choosing the right angles",
          name: "choosing-the-right-angles",
          isActive: false,
        },
      ],
      isMobile: true,
    };
  },
  components: {
    Guideline: () => import("../../components/photo-upload/Guideline"),
  },
  methods: {
    active(num) {
      this.sections[num].isActive = !this.sections[num].isActive;
    },
  },
  computed: {
    mobileMediaQuery: function () {
      return window.matchMedia("(min-width: 768px)");
    },
  },
  mounted() {
    // Check mobile screen
    this.mobileMediaQuery.addListener((e) => {
      if (e.matches) {
        this.isMobile = false;
      } else {
        this.isMobile = true;
      }
    });
    this.isMobile = !this.mobileMediaQuery.matches;
  },
  watch: {
    "isMobile": {
      handler() {
        this.$nextTick(() => {
          this.sections.forEach((section) => {
            section.isActive = !this.isMobile
            if (section.name === "clean-up") {
              section.isActive = true
            }
          })
        })
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss">
.photo-guide-content {
  color: #404040;
  max-width: 1080px;
  padding: 30px 15px 15px 15px;
  margin: 30px 0;
  background-color: #ffffff;
  border-radius: 20px;

  .collapse {
    margin-top: 10px !important;
  }

  .top, .section-1, .section-2, .section-3, .section-4 {
    p,
    li {
      font-size: 16px !important;
    }

    h4 {
      margin-bottom: 10px;
    }

    .bold {
      font-family: Avenir Next LT W05 Regular;
    }

    ul {
      padding-inline-start: 30px !important;
    }

    p,
    li {
      font-family: Avenir Next LT W05 Thin;
      font-weight: 600;
    }
  }
  .sections {
    width: 100%;

    .item {
      min-height: 60px;

      p {
        color: #637380;
        font-size: 14px;
        font-weight: bold;
        line-height: 20px;
      }

      img {
        width: 25px;
        height: 25px;
        cursor: pointer;
        transform: rotate(90deg);
      }
    }

    hr {
      border-color: #404040;
      margin-top: 0;
      margin-bottom: 0;
    }

    .quick-answer {
      font-size: 14px;
      margin-top: 16px;
      margin-bottom: 41px;
    }
  }
  .guided {
    color: #0AB76D;
  }
}
</style>